(function($){

    $(document).ready(function() {

        /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
        /* ~~~~~~~~~~ Plugin Inits ~~~~~~~~~~ */
        /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

            /* ~~~~~~~~~~ Match height ~~~~~~~~~~ */

            // $('.match-height').matchHeight({
            //     byRow: true,
            //     property: 'min-height',
            //     target: null,
            //     remove: false
            // });


            /* ~~~~~~~~~~ Chosen ~~~~~~~~~~ */

            // $(".ginput_container_select").chosen({disable_search_threshold: 10});


            /* ~~~~~~~~~~ Lazy Loading ~~~~~~~~~~ */

            // $('.lazy').Lazy();


            /* ~~~~~~~~~~ MixitUp ~~~~~~~~~~ */

            if($('.events__mixitup').length) {
                var initialFilter = '.internal';
                var hash = window.location.hash.replace(/^#/g, '');

                if (hash) {
                    initialFilter = '.' + hash;
                }

                var eventsMixer = mixitup('.events__mixitup', {
                    load: {
                        filter: initialFilter
                    },
                    selectors: {
                        control: '.mixitup-control'
                    },
                    pagination: {
                        limit: 6,
                        maintainActivePage: false,
                        loop: true,
                        hidePageListIfSinglePage: true
                    }
                });
            }

            if($('.events-taxonomy__mixitup').length) {
                var eventsTaxonomyMixer = mixitup('.events-taxonomy__mixitup', {
                    selectors: {
                        control: '.mixitup-control'
                    },
                    pagination: {
                        limit: 6,
                        maintainActivePage: false,
                        loop: true,
                        hidePageListIfSinglePage: true
                    }
                });
            }

            if($('.news-archive-page').length) {

                var newsMixer = mixitup('.news-archive__mixitup', {
                    load: {
                        filter: 'all'
                    },
                    selectors: {
                        control: '.mixitup-control'
                    },
                    pagination: {
                        limit: 6,
                        maintainActivePage: false,
                        loop: true,
                        hidePageListIfSinglePage: true
                    }
                });
            }

            if($('.members-search-result').length) {
                var mixer = mixitup('.members-search-result__mixitup', {
                    multifilter: {
                        enable: true
                    },
                    selectors: {
                        control: '.mixitup-control'
                    },
                    pagination: {
                        limit: 6,
                        maintainActivePage: false,
                        loop: true,
                        hidePageListIfSinglePage: true
                    }
                });
            }


        /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
        /* ~~~~~~~~~~ Functions ~~~~~~~~~~ */
        /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

            /* ~~~~~~~~~~ Set animation scroll when URL is with #anchor and make smooth scroll ~~~~~~~~~~ */

            $(function(){
                if ( window.location.hash ) scroll(0,0);
                setTimeout( function() { scroll(0,0); }, 1);

                var headerHeight = $('.site-header').height();

                if($('#wpadminbar').length) {
                    headerHeight += $('#wpadminbar').height();
                }

                $('.scroll').on('click', function(e) {
                    e.preventDefault();

                    $('html, body').animate({
                        scrollTop: ($($(this).attr('href')).offset().top - headerHeight) + 'px'
                    }, 1000, 'swing');
                });

                if(window.location.hash) {
                    $('html, body').animate({
                        scrollTop: ($(window.location.hash).offset().top - headerHeight) + 'px'
                    }, 1000, 'swing');
                }
            });


            /* ~~~~~~~~~~ First content element fix ~~~~~~~~~~ */

            $('.content').prepend('<span class="first-element-fix"></span>');
            $('blockquote').prepend('<span class="first-element-fix"></span>');
            $('.panel').prepend('<span class="first-element-fix"></span>');
            $('.card').prepend('<span class="first-element-fix"></span>');


            /* ~~~~~~~~~~ Delete empty <p> elements ~~~~~~~~~~~ */

            $('p').each(function() {
                var $this = $(this);
                if($this.html().replace(/\s|&nbsp;/g, '').length === 0)
                    $this.remove();
            });


            /* ~~~~~~~~~~ Change navigation after scroll ~~~~~~~~~~ */

            $(window).scroll(function() {
                if ($(this).scrollTop() >= 100) {
                    $('.site-header').addClass('site-header--scrolled');
                } else {
                    $('.site-header').removeClass('site-header--scrolled');
                }
            });


            /* ~~~~~~~~~~ Replace all SVG images with inline SVG ~~~~~~~~~~ */

            jQuery('img.svg').each(function(){
                var $img = jQuery(this);
                var imgID = $img.attr('id');
                var imgClass = $img.attr('class');
                var imgURL = $img.attr('src');

                jQuery.get(imgURL, function(data) {
                    var $svg = jQuery(data).find('svg');

                    if(typeof imgID !== 'undefined') {
                        $svg = $svg.attr('id', imgID);
                    }

                    if(typeof imgClass !== 'undefined') {
                        $svg = $svg.attr('class', imgClass+' replaced-svg');
                    }

                    $svg = $svg.removeAttr('xmlns:a');
                    $img.replaceWith($svg);
                }, 'xml');
            });


            /* ~~~~~~~~~~ Fix flyout menu parent submenu link unclickable issue ~~~~~~~~~ */

            jQuery('.mega-menu li.is-dropdown-submenu-parent > a').click(function(){
            	jQuery(this).unbind('click');
            });

            jQuery('.menu-item-has-children > a').click(function(){
                jQuery(this).unbind('click');
            });


            /* ~~~~~~~~~~ Change dropdown button name after click ~~~~~~~~~ */

            if($('.hello-section__tabs__dropdown').length) {
                $(".dropdown-pane .tabs-title a, .dropdown-pane .tabs-title button").click(function(){
                    $(".hello-section__tabs__dropdown__button").html($(this).text());
                });
            }

            if($('.news-archive-page').length) {
                $(".filters .dropdown-pane li a").click(function(){
                    $(".filters__button span").html($(this).text());
                });
            }


            /* ~~~~~~~~~~ Add tick for register stage ~~~~~~~~~ */

            if($('.registration-stage--2.registration-stage--current').length) {
                $(".registration-stage.registration-stage--1").addClass('registration-stage--complete');
            }

            if($('.registration-stage--3.registration-stage--current').length) {
                $(".registration-stage.registration-stage--1").addClass('registration-stage--complete');
                $(".registration-stage.registration-stage--2").addClass('registration-stage--complete');
            }


            /* ~~~~~~~~~~ Add current to menu ~~~~~~~~~ */

            if($('.post-type-archive-funding_output, .single-funding_output').length) {
                $(".menu-item-object-funding_opportunitie").addClass('current-menu-item');
            }

            if($('.page-template-past-event').length) {
                $(".menu-item-object-event").addClass('current-menu-item');
            }

            $('[data-tabs] > li > a, [data-tabs] > li > button').on('click', function() {
                $('.dropdown-pane').foundation('close');
            });


    });


    /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
    /* ~~~~~~~~~~ Resuable functions ~~~~~~~~~~ */
    /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

        /* ~~~~~~~~~~ Check if current devices is mobile ~~~~~~~~~~ */

        function isMobile() {
            try{ document.createEvent("TouchEvent"); return true; }
            catch(e){ return false; }
        }

})(jQuery);